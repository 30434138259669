<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <!-- PDF Generator -->

        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          :filename="filename"
          :pdf-quality="1"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="landscape"
          pdf-content-width="100%"

          ref="html2Pdf"
        >
          <section class="p-10" slot="pdf-content">
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="vx-row">
                  <div class="vx-col w-1/3 mt-base">

                  </div>
                  <div class="vx-col w-1/3 mt-base text-center">
                    <div class="titre_etat_regl">
                      <h1>Etat Article Sortie</h1>
                    </div>
                  </div>
                  <div class="vx-col w-1/3 mt-base text-right">
                    <!-- {{proforma.createdAt | moment("calendar", "July 10 2011") }} -->
                    <h4>Officine: {{officineName}} </h4>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <div class="vx-col w-full text-center">
                    <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
                  </div>
                </div>
                <div class="vx-row mt-5">
                  <!-- {{reglements_client}} -->
                  <table class="w-full customtable" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th class="customth" colspan="1" style="border-right-width: 0px;" >N°Cmmd</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;" >Date Cmmd</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;" >Etiquette</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">Marque</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">Taille</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">Couleur</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">Catégorie</th>
                        <th class="customth" colspan="1" style="border-right-width: 0px;">PvTTC</th>
                        <th class="customth" colspan="1" style="">Qte</th>
                      </tr>
                    </thead>
                    <!-- v-if="br.length >0" -->
                    <tbody>
                      <template v-for="(tr, indextr) in stock">
                        <tr :key="indextr" v-if="tr.IdMont1 != null">
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.RefCmmd}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.DateCommd | moment("calendar", "July 10 2011") }}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getArticle(tr.IdMont1).Etiquette}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getArticleMarque(tr.IdMont1)}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">
                            {{tr.IdMont1 ? (getArticle(tr.IdMont1).Taille ? getArticle(tr.IdMont1).Taille[0].Longeur : '') : ''}}/{{tr.IdMont1 ? (getArticle(tr.IdMont1).Taille ? getArticle(tr.IdMont1).Taille[0].Nez : '') : ''}}/{{tr.IdMont1 ? (getArticle(tr.IdMont1).Taille ? getArticle(tr.IdMont1).Taille[0].Hauteur : '') : ''}}
                          </td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont1 ? getArticle(tr.IdMont1).Couleur : ''}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont1 ? getCategorieName(tr.IdMont1) : ''}}</td>
                          <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;border-bottom-width: 0px;">{{moneyFormatter(tr.PrixMont1)}}</td>
                          <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.IdMont1 ? 1 : 0}}</td>
                        </tr>
                        <tr v-bind:key="indextr" v-if="tr.IdMont2 !=null">
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.RefCmmd}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.DateCommd | moment("calendar", "July 10 2011") }}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont2 ? getArticle(tr.IdMont2).Etiquette : ''}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont2 ? getArticleMarque(tr.IdMont2) : ''}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">
                            {{tr.IdMont2 ? (getArticle(tr.IdMont2).Taille ? getArticle(tr.IdMont2).Taille[0].Longeur : '') : ''}}/{{tr.IdMont2 ? (getArticle(tr.IdMont2).Taille ? getArticle(tr.IdMont2).Taille[0].Nez : '') : ''}}/{{tr.IdMont2 ? (getArticle(tr.IdMont2).Taille ? getArticle(tr.IdMont2).Taille[0].Hauteur : '') : ''}}
                          </td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont2 ? getArticle(tr.IdMont2).Couleur : ''}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont2 ? getCategorieName(tr.IdMont2) : ''}}</td>
                          <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;border-bottom-width: 0px;">{{moneyFormatter(tr.PrixMont2)}}</td>
                          <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.IdMont2 ? 1 : 0}}</td>
                        </tr>
                        <tr v-bind:key="indextr" v-if="tr.IdMont3 !=null">
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.RefCmmd}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.DateCommd | moment("calendar", "July 10 2011") }}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont3 ? getArticle(tr.IdMont3).Etiquette : ''}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont3 ? getArticleMarque(tr.IdMont3) : ''}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">
                            {{tr.IdMont3 ? (getArticle(tr.IdMont3).Taille ? getArticle(tr.IdMont3).Taille[0].Longeur : '') : ''}}/{{tr.IdMont3 ? (getArticle(tr.IdMont3).Taille ? getArticle(tr.IdMont3).Taille[0].Nez : '') : ''}}/{{tr.IdMont3 ? (getArticle(tr.IdMont3).Taille ? getArticle(tr.IdMont3).Taille[0].Hauteur : '') : ''}}
                          </td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont3 ? getArticle(tr.IdMont3).Couleur : ''}}</td>
                          <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont3 ? getCategorieName(tr.IdMont3) : ''}}</td>
                          <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;border-bottom-width: 0px;">{{moneyFormatter(tr.PrixMont3)}}</td>
                          <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.IdMont3 ? 1 : 0}}</td>
                        </tr>
                      </template>
                    </tbody>

                    <tfoot>
                      <tr>
                        <th class="customth text-center" colspan="7" style="border-right-width: 0px;">TOTAL</th>
                        <th class="customth text-right" colspan="1" style="border-right-width: 0px;">{{totalPvTtc(stock)}}</th>
                        <th class="customth text-right" colspan="1" >{{totalArticle(stock)}}</th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </vue-html2pdf>

        <!-- PDF Generator end -->
      <vx-card>
        <div class="vx-row">
          <div class="vx-col md:w-1/6 mt-4">
            <label class="text-sm">Date Debut</label>
            <flat-pickr v-model.lazy="DateDebut" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateDebut" />
          </div>
          <div class="vx-col md:w-1/6 mt-4">
            <label class="text-sm">Date Fin</label>
            <flat-pickr v-model.lazy="DateFin" :config="{ dateFormat: 'd F Y'}" class="w-full" name="DateFin" />
          </div>
          <div class="vx-col md:w-1/6 mt-4">
            <vs-select v-if="principale" label="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="onSelectOfficine(idOfficine)">
              <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
            </vs-select>
          </div>
          <div class="vx-col md:w-1/6 mt-10">
            <vs-button ref="loadableButton" id="button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-search" @click="getArticleSortieOnSpecifiquePeriode()">VALIDER</vs-button>
          </div>
          <div class="vx-col md:w-1/6 mt-10">
            <vs-button id="pdf-button-with-loading" class="w-full vs-con-loading__container" color="primary" icon-pack="feather" icon="icon-file" @click="printStockToPdf()">IMPRESSION</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
    <div  class="vx-col w-full mt-5">
      <vx-card id="corps-proforma">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="vx-row">
              <div class="vx-col w-1/3 mt-base">

              </div>
              <div class="vx-col w-1/3 mt-base text-center">
                <div class="titre_etat_regl">
                  <h1>Etat Article Sortie</h1>
                </div>
              </div>
              <div class="vx-col w-1/3 mt-base text-right">
                <!-- {{proforma.createdAt | moment("calendar", "July 10 2011") }} -->
                <h4>Officine: {{officineName}} </h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full text-center">
                <h4>Période du {{DateDebut | moment("calendar", "July 10 2011") }} au {{DateFin | moment("calendar", "July 10 2011") }}</h4>
              </div>
            </div>
            <div class="vx-row mt-5">
              <!-- {{reglements_client}} -->
              <table class="w-full customtable" cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th class="customth" colspan="1" style="border-right-width: 0px;" >N°Cmmd</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;" >Date Cmmd</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;" >Etiquette</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">Marque</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">Taille</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">Couleur</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">Catégorie</th>
                    <th class="customth" colspan="1" style="border-right-width: 0px;">PvTTC</th>
                    <th class="customth" colspan="1" style="">Qte</th>
                  </tr>
                </thead>
                <!-- v-if="br.length >0" -->
                <tbody>
                  <template v-for="(tr, indextr) in stock">
                    <tr :key="indextr" v-if="tr.IdMont1 != null">
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.RefCmmd}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.DateCommd | moment("calendar", "July 10 2011") }}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getArticle(tr.IdMont1).Etiquette}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{getArticleMarque(tr.IdMont1)}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">
                        {{tr.IdMont1 ? (getArticle(tr.IdMont1).Taille ? getArticle(tr.IdMont1).Taille[0].Longeur : '') : ''}}/{{tr.IdMont1 ? (getArticle(tr.IdMont1).Taille ? getArticle(tr.IdMont1).Taille[0].Nez : '') : ''}}/{{tr.IdMont1 ? (getArticle(tr.IdMont1).Taille ? getArticle(tr.IdMont1).Taille[0].Hauteur : '') : ''}}
                      </td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont1 ? getArticle(tr.IdMont1).Couleur : ''}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont1 ? getCategorieName(tr.IdMont1) : ''}}</td>
                      <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;border-bottom-width: 0px;">{{moneyFormatter(tr.PrixMont1)}}</td>
                      <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.IdMont1 ? 1 : 0}}</td>
                    </tr>
                    <tr v-bind:key="indextr" v-if="tr.IdMont2 !=null">
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.RefCmmd}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.DateCommd | moment("calendar", "July 10 2011") }}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont2 ? getArticle(tr.IdMont2).Etiquette : ''}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont2 ? getArticleMarque(tr.IdMont2) : ''}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">
                        {{tr.IdMont2 ? (getArticle(tr.IdMont2).Taille ? getArticle(tr.IdMont2).Taille[0].Longeur : '') : ''}}/{{tr.IdMont2 ? (getArticle(tr.IdMont2).Taille ? getArticle(tr.IdMont2).Taille[0].Nez : '') : ''}}/{{tr.IdMont2 ? (getArticle(tr.IdMont2).Taille ? getArticle(tr.IdMont2).Taille[0].Hauteur : '') : ''}}
                      </td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont2 ? getArticle(tr.IdMont2).Couleur : ''}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont2 ? getCategorieName(tr.IdMont2) : ''}}</td>
                      <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;border-bottom-width: 0px;">{{moneyFormatter(tr.PrixMont2)}}</td>
                      <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.IdMont2 ? 1 : 0}}</td>
                    </tr>
                    <tr v-bind:key="indextr" v-if="tr.IdMont3 !=null">
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.RefCmmd}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.DateCommd | moment("calendar", "July 10 2011") }}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont3 ? getArticle(tr.IdMont3).Etiquette : ''}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont3 ? getArticleMarque(tr.IdMont3) : ''}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">
                        {{tr.IdMont3 ? (getArticle(tr.IdMont3).Taille ? getArticle(tr.IdMont3).Taille[0].Longeur : '') : ''}}/{{tr.IdMont3 ? (getArticle(tr.IdMont3).Taille ? getArticle(tr.IdMont3).Taille[0].Nez : '') : ''}}/{{tr.IdMont3 ? (getArticle(tr.IdMont3).Taille ? getArticle(tr.IdMont3).Taille[0].Hauteur : '') : ''}}
                      </td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont3 ? getArticle(tr.IdMont3).Couleur : ''}}</td>
                      <td class="customtd" colspan="1" style="border-right-width: 0px;border-bottom-width: 0px;">{{tr.IdMont3 ? getCategorieName(tr.IdMont3) : ''}}</td>
                      <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;border-bottom-width: 0px;">{{moneyFormatter(tr.PrixMont3)}}</td>
                      <td class="customtd text-right" colspan="1" style="border-bottom-width: 0px;">{{tr.IdMont3 ? 1 : 0}}</td>
                    </tr>
                  </template>
                </tbody>

                <tfoot>
                  <tr>
                    <th class="customth text-center" colspan="7" style="border-right-width: 0px;">TOTAL</th>
                    <th class="customth text-right" colspan="1" style="border-right-width: 0px;">{{totalPvTtc(stock)}}</th>
                    <th class="customth text-right" colspan="1" >{{totalArticle(stock)}}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
/* lazy loading Vue components */
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const VueHtml2pdf = () => import('vue-html2pdf')
export default {
  components: {
    flatPickr,
    VueHtml2pdf
  },
  data () {
    return {
      activeUserInfos: null,
      DateDebut: null,
      DateFin: null,
      idOfficine: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null,
      filename: '',
      officineName: ''
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    },
    stock () {
      return this.$store.state.etat.articles_sortie
    },
    principale () {
      return this.$store.getters['officine/officineIsPrincipale'](JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')).IdOfficine : null)
    }
  },
  methods: {
    getCategorieName (id) {
      const article = this.$store.getters['stock/getArticleById'](id)
      return this.$store.getters['categorie_article/getCategorieName'](article.IdCateg)
    },
    getArticle (id) {
      return id ? this.$store.getters['stock/getArticleById'](id) : ''
    },

    getArticleMarque (id) {
      const article = this.$store.getters['stock/getArticleById'](id)
      return this.$store.getters['marque/getMarqueName'](article.IdMarque)
    },
    onSelectOfficine (id) {
      const officine = this.$store.state.officine.officines.find((o) => o._id === id)
      this.officineName = officine.NomOffic
    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    totalPvTtc (stock) {
      const montant = stock.reduce(function (accumulator, article) {
        return accumulator + (+article.PrixMont1 + +article.PrixMont2)
      }, 0)
      return this.moneyFormatter(montant)
    },
    totalArticle (stock) {
      return stock.reduce((accumulator, article) => {
        return accumulator + ((article.IdMont1 ? 1 : 0) + (article.IdMont2 ? 1 : 0) + (article.IdMont3 ? 1 : 0))
      }, 0)
    },
    printStockToPdf () {

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#pdf-button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      setTimeout(() => {
        this.$vs.loading.close('#pdf-button-with-loading > .con-vs-loading')
      }, 10000)

      this.$refs.html2Pdf.generatePdf()

    },
    partClientSousTotal (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + +commande.PartClient
      }, 0)
      return this.moneyFormatter(montant)
    },
    partAssuSousTotal (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + +commande.PartAssu
      }, 0)
      return this.moneyFormatter(montant)
    },
    totalCmde (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + +commande.PartAssu + +commande.PartClient
      }, 0)
      return this.moneyFormatter(montant)
    },
    sousTotalRegler (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + +commande.reglements.reduce(function (accumulator, part) {
          return accumulator + +part.Montant
        }, 0)
      }, 0)
      return this.moneyFormatter(montant)
    },
    soldeTotal (commandes) {
      const montant = commandes.reduce(function (accumulator, commande) {
        return accumulator + (+commande.PartClient + +commande.PartAssu) - +commande.reglements.reduce(function (accumulator, part) {
          return accumulator + +part.Montant
        }, 0)
      }, 0)
      return this.moneyFormatter(montant)
    },
    getArticleSortieOnSpecifiquePeriode () {
      const payload = {
        DateDebut: this.DateDebut,
        DateFin: this.DateFin,
        idOfficine: this.idOfficine
      }

      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: '#button-with-loading',
        type: 'sound',
        scale: 0.45
      })

      this.$store.dispatch('etat/getArticleSortieOnSpecifiquePeriode', payload)
        .then(() => {
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close('#button-with-loading > .con-vs-loading')
        })

    },
    getArticleById (id) {
      this.$store.dispatch('stock/getArticleById', id)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getAllArticleSortie () {
      this.$store.dispatch('stock/getAllArticleSortie')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    getCategorieArticles () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })

    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .then(() => {
        })
        .catch(err => {
          console.error(err)
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {
          this.onSelectOfficine(this.idOfficine)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    if (!(this.$store.state.officine.officines.length > 0)) this.getOfficines()
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticles()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
    if (!(this.$store.state.stock.articles.length > 0)) this.getAllArticleSortie()
  }
}
</script>
<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
/* th, td {
  border: 1px solid black;
  padding: 4px;
}
 */
.customtable, .customth, .customtd {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 4px;
  font-size: 12px;
}

.titre_etat_regl{
  border: 1px solid;
  background-color: #CFCFCF;
}
</style>
